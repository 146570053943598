/* Bootstrap overrides
-------------------------------------------------- */
$body: #000;
$headings-font-weight: 400 !important;
$primary: #0037b8;
$light: #fafafa;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

/* Base
-------------------------------------------------- */
html {
    min-height: 100%;
    font-size: 14px;
}

.wrapper {
    min-height: 100vh;
}

.opacity-6 {
    opacity: 0.6;
}

.dapp-icon {
    padding: 5px;
    border-radius: 50%;
    background-color: $light;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.icon-medium {
        width: 80px;
        height: 80px;
    }
}

/* Unlock
-------------------------------------------------- */

.unlock {
    background-image: linear-gradient(
        to bottom right,
        rgba(0, 55, 184, 0.8),
        rgba(183, 166, 0, 0.8)
    );
}

.unlock-card {
    border-radius: 12px;
}

.unlock-card h4 {
    margin-top: 12px;
    margin-left: 1rem;
    font-size: 20px;
    font-weight: bold;
}

.login-footer {
    margin-top: 24px;
    margin-bottom: 12px;
}

.login-footer a {
    text-decoration: underline;
    color: black;
}

.maiar-symbol {
    width: 24px;
    margin-right: 0.9rem;
}

.maiar-app-symbol {
    height: 24px !important;
}

.ledger-symbol {
    height: 18px !important;
}

.web-wallet-symbol {
    height: 18px !important;
}

.dapp-core-ui-component .login-buttons_noExtensionButtonWrapper {
    display: none !important;
}

.login-buttons_wrapper {
    color: #1f2021 !important;
    text-align: left !important;
    width: 93%;
    margin-bottom: 16px !important;
    padding: 16px !important;
    color: $primary !important;
    background-color: white !important;
    font-size: 16px !important;
    border: 1px solid #cccccc !important;
    border-radius: 8px !important;
}

.login-buttons_wrapper:hover {
    border: 1px solid $primary !important;
}

.login-buttons-wrapper {
    width: 500px !important;
    padding-bottom: 8px;
}

.request-image {
    object-fit: contain;
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
    width: 85px;
    height: 25px;
}

.dapp-name {
    line-height: 1;
    border-left: 1px solid $gray-400;
    padding-left: 3px;
    margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    margin: 0 7px;

    button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $white;

        &:hover {
            opacity: 0.9;
        }
    }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
    cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .bg-blue .lds-ellipsis div {
        background: rgba(255, 255, 255, 0.4);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    }
}

/* Footer
-------------------------------------------------- */
footer {
    div {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        display: inline-block;
        font-weight: 400;
        line-height: 1.155rem;

        #heart {
            height: 16px;
            width: 16px;

            path {
                fill: #c00;
            }
        }
    }
}

/* Table
-------------------------------------------------- */
.table-wrapper {
    max-width: 100%;
    margin: 0 auto;
    overflow-x: scroll;
}

.table-pagination {
    padding: 0.5rem;
}

.action-buttons {
    min-width: 200px;
}

.btn-link {
    color: white !important;
    text-decoration: none !important;
}

.img-docs {
    max-width: 500px;
    max-height: 500px;
}
